import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import Block from "@insightfulscience/atomic-react/Block";
import Image from "@insightfulscience/atomic-react/Image";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import { useLocale } from "@insightfulscience/smart-react/i18";
import Inline from "@insightfulscience/atomic-react/Inline";
import routes from "../routes";

const iconPrime = "/images/geneious/logo/icon-prime.svg";
const iconBiologics = "/images/geneious/logo/icon-biologics.svg";

export default () => {
	const [t] = useLocale("header");
	return (
		<MegaMenu>
			<MegaMenuCol md="auto" lgOffset={6}>
				<MegaMenuItem spacing={{ lg: { my: 4 } }}>
					<Inline sx={{ textTransform: "uppercase", fontColor: "black--46" }}>Help Center</Inline>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 4 } }}>
					<MegaMenuItem.Link href={routes.header.support.gnPrimeSupport.index}>
						<Block utility={{ display: "flex", align: { items: "center" } }}>
							<Image src={iconPrime} spacing={{ mr: 2 }} />
							{t("SUPPORT_LIST.PRIME_TITLE")}
						</Block>
					</MegaMenuItem.Link>
				</MegaMenuItem>
				<MegaMenuItem spacing={{ lg: { my: 4 } }}>
					<MegaMenuItem.Link href={routes.header.support.gnBiologicsSupport.index}>
						<Block utility={{ display: "flex", align: { items: "center" } }}>
							<Image src={iconBiologics} spacing={{ mr: 2 }} />
							{t("SUPPORT_LIST.BIOLOGICS_TITLE")}
						</Block>
					</MegaMenuItem.Link>
				</MegaMenuItem>
			</MegaMenuCol>
		</MegaMenu>
	);
};
