import React from "react";
import MegaMenu from "@insightfulscience/atomic-react/MegaMenu";
import { MegaMenuCol } from "@insightfulscience/atomic-react/MegaMenu/Grid";
import MegaMenuTitle from "@insightfulscience/atomic-react/MegaMenu/Title";
import Block from "@insightfulscience/atomic-react/Block";
import MegaMenuItem from "@insightfulscience/atomic-react/MegaMenu/Item";
import Inline from "@insightfulscience/atomic-react/Inline";
import Image from "@insightfulscience/atomic-react/Image";
import { useLocale } from "@insightfulscience/smart-react/i18";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

const iconPrime = "/images/geneious/logo/icon-prime.svg";
const iconBiologics = "/images/geneious/logo/icon-biologics.svg";

export default () => {
	const [t] = useLocale("header");

	return (
		<MegaMenu utility={{ justify: "end" }}>
			<MegaMenuCol md={12} lg="shrink">
				<MegaMenuTitle>SIGN IN</MegaMenuTitle>
				<Block>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<NextLink Component={MegaMenuItem.Link} href={routes.header.signIn.index}>
							<Inline utility={{ display: "flex", align: { items: "center" } }}>
								<Image src={iconPrime} spacing={{ mr: 2 }} />
								{t("SIGNIN_LIST.PRIME")}
							</Inline>
						</NextLink>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.signIn.biologics}>
							<Inline utility={{ display: "flex", align: { items: "center" } }}>
								<Image src={iconBiologics} spacing={{ mr: 2 }} />
								{t("SIGNIN_LIST.BIOLOGICS")}
							</Inline>
						</MegaMenuItem.Link>
					</MegaMenuItem>
					<MegaMenuItem spacing={{ lg: { my: 4 } }}>
						<MegaMenuItem.Link href={routes.header.signIn.prime}>
							<Inline utility={{ display: "flex", align: { items: "center" } }}>
								<Image src={iconBiologics} spacing={{ mr: 2 }} />
								{t("SIGNIN_LIST.BIOLOGICS_STARTER")}
							</Inline>
						</MegaMenuItem.Link>
					</MegaMenuItem>
				</Block>
			</MegaMenuCol>
		</MegaMenu>
	);
};
