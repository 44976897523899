import React from "react";
import LogoLink from "@insightfulscience/atomic-react/LogoLink";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

export const logoPath = "/images/geneious/logo/logo-geneious.svg";
export const logoAlt = "Geneious by Dotmatics";

const prerenderedLogo = (
	<NextLink
		Component={LogoLink}
		href={routes.external.index}
		imgSrc={logoPath}
		alt={logoAlt}
		data-qa-selector="header-logo"
	/>
);

export default prerenderedLogo;
