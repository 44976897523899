import React from "react";
import Header from "@insightfulscience/atomic-react/Header";
import Button from "@insightfulscience/atomic-react/Button";
import Icon from "@insightfulscience/atomic-react/Icon";

import NextLink from "@insightfulscience/smart-react/NextLink";
import { bool } from "prop-types";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Navbar from "@insightfulscience/atomic-react/Navbar";
import NavbarDropdown from "@insightfulscience/atomic-react/NavbarDropdown";
import NavbarDropdownTitle from "@insightfulscience/atomic-react/NavbarDropdown/Title";
import NavbarDropdownMenu from "@insightfulscience/atomic-react/NavbarDropdown/Menu";
import NavbarItem from "@insightfulscience/atomic-react/Navbar/Item";
import NavbarItemLink from "@insightfulscience/atomic-react/Navbar/Link";
import routes from "../routes";
import prerenderedLogo from "../PrerenderedLogo";
import ProductsDropdown from "./ProductsDropdown";
import ResourcesDropdown from "./ResourcesDropdown";
import SupportDropdown from "./SupportDropdown";
import SignInDropdown from "./SignInDropdown";
import { HeaderTopBar } from "../HeaderTopBar";

const SiteHeader = ({ showTopBar = true, ...props }) => {
	const [t] = useLocale("header");

	return (
		<>
			{showTopBar && <HeaderTopBar />}
			<Header logo={prerenderedLogo} isBorderBottomNone {...props}>
				<Row sizing={{ w: "full" }} middle>
					<Col lg="auto" utility={{ justify: "center" }}>
						<Navbar nav="primary" type={1}>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("PRODUCTS")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<ProductsDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarItem>
								<NextLink Component={NavbarItemLink} href={routes.header.enterprise}>
									{" "}
									{t("ENTERPRISE")}
								</NextLink>
							</NavbarItem>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("RESOURCES")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<ResourcesDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("SUPPORT")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<SupportDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarItem>
								<NextLink Component={NavbarItemLink} href={routes.header.pricing}>
									{" "}
									{t("HOW_TO_BUY")}
								</NextLink>
							</NavbarItem>
						</Navbar>
					</Col>
					<Col lg="shrink">
						<Navbar nav="secondary" type={1}>
							<NavbarDropdown isSecondaryNav isMegaMenu>
								<NavbarDropdownTitle>{t("SIGNIN")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<SignInDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarItem secondary className="secondary-nav__item-trial">
								<NextLink Component={Button.Link} href={routes.header.freeTrial} size="s">
									{t("FREE_TRIAL")}
								</NextLink>
							</NavbarItem>
						</Navbar>
					</Col>
				</Row>
			</Header>
		</>
	);
};

SiteHeader.propTypes = {
	showTopBar: bool,
};

export default SiteHeader;

export const SiteHeaderFeature = props => {
	const [t] = useLocale("header");

	return (
		<Header
			logo={prerenderedLogo} // logo
			className="bg--transparent"
			isBorderBottomNone
			{...props}
		>
			<Navbar nav="primary" type={1} inverted>
				<NavbarItem>
					<NavbarItemLink href={routes.external.features}>{t("FEATURES")}</NavbarItemLink>
				</NavbarItem>
				<NavbarItem>
					<NavbarItemLink href={routes.external.resources}>{t("RESOURCES")}</NavbarItemLink>
				</NavbarItem>
				<NavbarItem>
					<NavbarItemLink href={routes.external.help}>{t("SUPPORT")}</NavbarItemLink>
				</NavbarItem>
				<NavbarItem>
					<NavbarItemLink href={routes.external.howToBuy}>{t("HOW_TO_BUY")}</NavbarItemLink>
				</NavbarItem>
				<NavbarItem>
					<NavbarItemLink href={routes.external.biopharma}>
						<Icon name="link-ext" />
						{t("GENEIOUS_BIOLOGICS")}
					</NavbarItemLink>
				</NavbarItem>
			</Navbar>
			<Navbar nav="secondary" type={1} inverted>
				<NavbarItem secondary>
					<NextLink Component={NavbarItemLink} href={routes.myAccount} className="nav__item-link">
						<Icon name="user-alt" /> {t("MY_ACCOUNT")}
					</NextLink>
				</NavbarItem>
				<NavbarItem secondary className="secondary-nav__item-trial">
					<Button.Link href={routes.freeTrial} size="s">
						<Icon name="arrow-to-bottom" /> {t("FREE_TRIAL")}
					</Button.Link>
				</NavbarItem>
			</Navbar>
		</Header>
	);
};
