import React from "react";
import Footer from "@insightfulscience/atomic-react/Footer";
import FooterContainer from "@insightfulscience/atomic-react/Footer/Container";
import FooterMainContainer from "@insightfulscience/atomic-react/Footer/Main";
import FooterNav, {
	FooterNavCol,
	FooterNavItemLink,
	FooterNavList,
	FooterNavListItem,
	FooterNavListTitle,
	FooterNavRow,
	FooterNavTitleLink,
} from "@insightfulscience/atomic-react/Footer/Nav";
import FooterSideBarContainer from "@insightfulscience/atomic-react/Footer/Sidebar";
import FooterCopyright, {
	FooterCopyrightLink,
	FooterCopyrightText,
} from "@insightfulscience/atomic-react/Footer/Copyright";
import Inline from "@insightfulscience/atomic-react/Inline";
import FooterLogo from "@insightfulscience/atomic-react/Footer/Logo";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { string } from "prop-types";
import Image from "@insightfulscience/atomic-react/Image";
import FooterSocial, { FooterSocialItemLink } from "@insightfulscience/atomic-react/Footer/Social";
import Html from "@insightfulscience/smart-react/Html";
import NextLink from "@insightfulscience/smart-react/NextLink";
import routes from "../routes";

const iconPrime = "/images/geneious/logo/icon-prime.svg";
const iconBiologics = "/images/geneious/logo/icon-biologics.svg";
const logoImg = "/images/geneious/logo/logo-geneious-dotmatics.svg";

const SiteFooter = ({ className = "" }) => {
	const [t] = useLocale("footer");

	return (
		<Footer className={className}>
			<FooterContainer>
				<FooterMainContainer>
					<FooterNav>
						<FooterNavRow>
							<FooterNavCol sm="auto" lg="auto">
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.footer.prime.index}
										utility={{ display: "flex" }}
									>
										{t("GENEIOUS_PRIME")} <Image src={iconPrime} spacing={{ ml: 2 }} />
									</NextLink>
								</FooterNavListTitle>
								<FooterNavRow>
									<FooterNavCol>
										<FooterNavList>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.index}
													isBold
												>
													{t("FEATURES")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.sangerSequencing}
												>
													{t("SANGER_SEQUENCING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.ngsSequencing}
												>
													{t("NGS_SEQUENCING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.assemblyMapping}
												>
													{t("ASSWMBLY_AND_MAPPING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.alignments}
												>
													{t("ALIGNMENTS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.phylogenetics}
												>
													{t("PHYLOHENETICS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.molecularCloning}
												>
													{t("MOLECULAR_CLONING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.primerDesign}
												>
													{t("PRIMER_DESIGN")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.crispr}
												>
													{t("CRISPR")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.annotatePredict}
												>
													{t("ANNOTATE_AND_PREDICT")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.microsatelliteAnalysis}
												>
													{t("MICROSATELLITE_ANALYSIS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.ncbiBlast}
												>
													{t("NCBI_Blast")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.workflows}
												>
													{t("WORKFLOWS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.convertFileFormats}
												>
													{t("CONVERT_FORMATS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.features.geneiousCloud}
												>
													{t("CLOUD")}
												</NextLink>
											</FooterNavListItem>
										</FooterNavList>
									</FooterNavCol>
									<FooterNavCol>
										<FooterNavList>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.index}
													isBold
												>
													{t("RESOURCES")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.freeTrial}
												>
													{t("FREE_TRIAL")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.pricing}
												>
													{t("PRICING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.quoteRequest}
												>
													{t("QUOTE_REQUEST")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.download}
												>
													{t("DOWNLOAD")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.plugins}
												>
													{t("PLUGINS")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.apiCli}
												>
													{t("API_CLI")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.academy}
												>
													{t("ACADEMY")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<FooterNavItemLink href={routes.footer.prime.resources.userManual}>
													{t("USER_MANUAL")}
												</FooterNavItemLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.courseLicenses}
												>
													{t("COURSE_LICENSES")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.igem}
												>
													{t("IGEM")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.prime.resources.whatsNew}
												>
													{t("WHATS_NEW")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<FooterNavItemLink href={routes.footer.prime.resources.support}>
													{t("SUPPORT")}
												</FooterNavItemLink>
											</FooterNavListItem>
										</FooterNavList>
									</FooterNavCol>
								</FooterNavRow>
							</FooterNavCol>
							<FooterNavCol sm="auto" lg="auto">
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.footer.biologics.index}
										utility={{ display: "flex" }}
									>
										{t("GENEIOUS_BIOLOGICS")} <Image src={iconBiologics} spacing={{ ml: 2 }} />
									</NextLink>
								</FooterNavListTitle>
								<FooterNavRow>
									<FooterNavCol>
										<FooterNavList>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.index}
													isBold
												>
													{t("FEATURES")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.antibodyDiscovery}
												>
													{t("ANTIBODY_DISCOVERY")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.candidateSelection}
												>
													{t("CANDIDATE_SELECTION")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.clusteringFiltering}
												>
													{t("CLUSTERING_FILTERING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.ngsSequencing}
												>
													{t("NGS_SCREENING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.dataSecurity}
												>
													{t("DATA_SECURITY")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.features.freeAntibodyAnnotator}
												>
													{t("FREE_ANTIBODY_ANNOTATOR")}
												</NextLink>
											</FooterNavListItem>
										</FooterNavList>
									</FooterNavCol>
									<FooterNavCol>
										<FooterNavList>
											<FooterNavListItem>
												<FooterNavItemLink href={routes.footer.biologics.resources.index} isBold>
													{t("RESOURCES")}
												</FooterNavItemLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.resources.freeTrial}
												>
													{t("FREE_TRIAL")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.resources.pricing}
												>
													{t("PRICING")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.resources.academy}
												>
													{t("ACADEMY")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<FooterNavItemLink href={routes.footer.biologics.resources.userGuide}>
													{t("USER_GUIDE")}
												</FooterNavItemLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<FooterNavItemLink href={routes.footer.biologics.resources.support}>
													{t("SUPPORT")}
												</FooterNavItemLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.resources.whatsNew}
												>
													{t("WHATS_NEW")}
												</NextLink>
											</FooterNavListItem>
											<FooterNavListItem>
												<NextLink
													Component={FooterNavItemLink}
													href={routes.footer.biologics.resources.contactSales}
												>
													{t("CONTACT_SALES")}
												</NextLink>
											</FooterNavListItem>
										</FooterNavList>
									</FooterNavCol>
								</FooterNavRow>
							</FooterNavCol>
							<FooterNavCol sm="auto" lg="shrink">
								<FooterNavListTitle>{t("COMPANY")}</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={routes.footer.company.aboutGeneious}
										>
											{t("ABOUT_GENEIOUS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.footer.company.customers}>
											{t("CUSTOMERS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.footer.company.citations}>
											{t("CITATIONS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.footer.company.dotmatics}>
											{t("DOTMATICS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.footer.company.portfolio}>
											{t("PORTFOLIO")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<FooterNavItemLink href={routes.footer.company.careers}>
											{t("CAREERS")}
										</FooterNavItemLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.footer.company.security}>
											{t("SECURITY")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.footer.company.contact}>
											{t("CONTACT")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
						</FooterNavRow>
					</FooterNav>
				</FooterMainContainer>
				<FooterSideBarContainer>
					<FooterCopyright>
						<NextLink Component={FooterLogo} logoPath={logoImg} logoAlt="" href="/" />
						<FooterCopyrightText>
							<FooterCopyrightText.CompanyName>
								&copy; {new Date().getFullYear()} {t("DOTMATICS")}
							</FooterCopyrightText.CompanyName>
							<FooterCopyrightLink href={routes.external.privacyPolicy} spacing={{ mb: 2 }}>
								{t("PRIVACY")}
							</FooterCopyrightLink>
							<br />
							<NextLink
								Component={FooterCopyrightLink}
								href={routes.footer.legal}
								spacing={{ mb: 2 }}
							>
								{t("LEGAL")}
							</NextLink>
							<br />
							<FooterCopyrightLink spacing={{ mb: 2 }} href={routes.footer.doNotSell}>
								<Html>{t("DO_NOT_SHELL")}</Html>
							</FooterCopyrightLink>
							<br />
							<FooterCopyrightLink
								target="_blank"
								href={routes.external.trademarks}
								spacing={{ mb: 2 }}
							>
								{t("TRADEMARKS")}
							</FooterCopyrightLink>
						</FooterCopyrightText>
						<FooterSocial>
							<FooterSocialItemLink href={routes.footer.twitter} name="twitter-square" />
							<FooterSocialItemLink href={routes.footer.youtube} name="youtube-square" />
							<FooterSocialItemLink href={routes.footer.linkedin} name="linkedin-square" />
						</FooterSocial>
					</FooterCopyright>
				</FooterSideBarContainer>
			</FooterContainer>
		</Footer>
	);
};

SiteFooter.propTypes = {
	className: string,
};

export default SiteFooter;

export const SiteFooterFeature = () => {
	const [t] = useLocale("footer");
	return (
		<Footer className="bg-color-6" isBorderNone>
			<FooterContainer
				utility={{ border: { top: { width: 1, color: "white--15" } } }}
				spacing={{ pt: 6 }}
			>
				<FooterMainContainer md={12} lg={10}>
					<FooterNav>
						<FooterNavRow>
							<FooterNavCol sm={6} md={3} lg={3}>
								<FooterNavListTitle>
									<NextLink Component={FooterNavTitleLink} weight="bold">
										{t("PRODUCTS")}
									</NextLink>
								</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.features}>
											{t("GENEIOUS_PRIME")}
											<Inline
												sx={{ fontSize: "xs", fontColor: "black--15" }}
												utility={{ display: "block" }}
											>
												{t("GENEIOUS_PRIME_DESCRIPTION")}
											</Inline>
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.biopharma}>
											{t("GENEIOUS_BIOLOGICS")}
											<Inline
												sx={{ fontSize: "xs", fontColor: "black--15" }}
												utility={{ display: "block" }}
											>
												{t("GENEIOUS_BIOLOGICS_DESCRIPTION")}
											</Inline>
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} lg={2}>
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.external.features}
									>
										{t("GENEIOUS_PRIME")}
									</NextLink>
								</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.features}>
											{t("ALL_FEATURES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/sanger-sequence-analysis/`}
										>
											{t("SANGER_SEQUENCING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/ngs-visualization-downstream-analysis/`}
										>
											{t("NGS_SEQUENCING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/assembly-mapping/`}
										>
											{t("ASSWMBLY_AND_MAPPING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/molecular-cloning/`}
										>
											{t("MOLECULAR_CLONING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/phylogenetic-tree-building/`}
										>
											{t("PHYLOHENETICS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.crispr}>
											{t("CRISPR")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.whatsNew}>
											{t("WHATS_NEW")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} lg={2}>
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.external.resources}
									>
										{t("RESOURCES")}
									</NextLink>
								</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.tutorials}>
											{t("PRODUCT_TUTORIAL")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.manual}>
											{t("MANUAL")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.download}>
											{t("DOWNLOAD")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.plugins}>
											{t("PLUGINS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.coronavirus}>
											{t("CORONAVIRUS_RESOURCES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink
											Component={FooterNavItemLink}
											href={`${routes.external.features}/import-export-sequence-data/`}
										>
											{t("IMPORT_COMMON_FILE_TYPES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.help}>
											{t("SUPPORT")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} lg={2}>
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.external.howToBuy}
									>
										{t("BUY_GENEIOUS")}
									</NextLink>
								</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.freeTrial}>
											{t("FREE_TRIAL")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.howToBuy}>
											{t("PRICING")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.quote}>
											{t("QUOTE_REQUEST")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.courseLicenses}>
											{t("COURSE_LICENSES")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.igem}>
											{t("IGEM")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
							<FooterNavCol sm={6} lg={3}>
								<FooterNavListTitle>
									<NextLink
										Component={FooterNavTitleLink}
										weight="bold"
										href={routes.external.about}
									>
										{t("COMPANY")}
									</NextLink>
								</FooterNavListTitle>
								<FooterNavList>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.about}>
											{t("ABOUT")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.dotmatics}>
											{t("DOTMATICS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.portfolio}>
											{t("PORTFOLIO")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.careersExternal}>
											{t("CAREERS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.customers}>
											{t("CUSTOMERS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.citations}>
											{t("CITATIONS")}
										</NextLink>
									</FooterNavListItem>
									<FooterNavListItem>
										<NextLink Component={FooterNavItemLink} href={routes.external.contactUs}>
											{t("CONTACT")}
										</NextLink>
									</FooterNavListItem>
								</FooterNavList>
							</FooterNavCol>
						</FooterNavRow>
					</FooterNav>
				</FooterMainContainer>
				<FooterSideBarContainer lg={2}>
					<FooterNav className="mt-8--sm">
						<FooterNavRow center>
							<FooterNavCol sm={12} md={5} lg={12}>
								<FooterCopyright>
									<FooterLogo
										logoPath="/images/geneious/logo/logo-geneious-white.svg"
										logoAlt="logoAlt.geneious"
									/>
									<FooterCopyrightText>
										<FooterCopyrightText.CompanyName>
											&copy; {new Date().getFullYear()} Dotmatics
										</FooterCopyrightText.CompanyName>
										<FooterCopyrightLink
											href={routes.external.privacyPolicy}
											className="display-block mb-2"
										>
											{t("PRIVACY")}
										</FooterCopyrightLink>
										<FooterCopyrightLink
											href={routes.external.termsOfService}
											className="display-block mb-2"
										>
											{t("LEGAL")}
										</FooterCopyrightLink>
										<FooterCopyrightLink
											href={routes.external.doNotSell}
											className="display-flex justify-content-center mb-2"
										>
											<span className="max-w-50--sm">{t("DO_NOT_SHELL")}</span>
										</FooterCopyrightLink>
									</FooterCopyrightText>
								</FooterCopyright>
							</FooterNavCol>
						</FooterNavRow>
					</FooterNav>
				</FooterSideBarContainer>
			</FooterContainer>
		</Footer>
	);
};
