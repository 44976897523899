import React from "react";
import { bool, elementType, node, string } from "prop-types";
import Layout from "@insightfulscience/atomic-react/Layout";
import SiteFooter from "../SiteFooter";
import SiteHeader from "../SiteHeader";

const SiteLayoutPure = ({
	bodyClassName = "",
	footerClassName = "",
	children,
	headerClassName,
	HeaderComponent = SiteHeader,
	...props
}) => {
	return (
		<Layout>
			<Layout.Header>
				<HeaderComponent {...props} className={headerClassName} />
			</Layout.Header>
			<Layout.Body className={bodyClassName}>{children}</Layout.Body>
			<Layout.Footer>
				<SiteFooter className={footerClassName} />
			</Layout.Footer>
		</Layout>
	);
};

SiteLayoutPure.propTypes = {
	bodyClassName: string,
	footerClassName: string,
	headerClassName: string,
	children: node,
	hero: bool,
	HeaderComponent: elementType,
};

const SiteLayout = props => {
	return <SiteLayoutPure {...props} />;
};

SiteLayout.Pure = SiteLayoutPure;

export default SiteLayout;
